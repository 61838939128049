<template>
  <v-navigation-drawer v-model="model" width="300">
    <template #prepend>
      <v-list-item class="d-flex justify-center px-0 mb-1" :elevation="4">
        <v-img :src="themeStore.sidebarLogo" :height="headbarHeight - 8" :width="300" />
      </v-list-item>
      <v-progress-linear v-if="dashboardsLoading" indeterminate />
      
      <v-list-item v-if="personalSettings.showDevicesSearchBar">
        <v-text-field
          v-model="search"
          :label="$t('Sidebar.SEARCH_LABEL_MESSAGE')"
          flat
          hide-details
          clearable
        />
      </v-list-item>
    </template>
    <v-list ref="el" open-strategy="single" class="h-100" active-class="text-primary" lines="two">
      <v-virtual-scroll :items="elements" :height="height">
        <template #default="{ item }">
          <sidebar-business-group-element v-if="item.type === 'BUSINESS_GROUP'" :business-group="item" />
          <sidebar-device-element v-else-if="item.type === 'REAL_DEVICE'" :device="item" />
          <sidebar-device-element v-else-if="item.type === 'VIRTUAL_DEVICE'" :device="item" />
          <sidebar-dashboards v-else-if="item.type === 'DASHBOARDS'" :dashboards="item.elements" :loading="dashboardsLoading" />
          <sidebar-linked-dashboards v-else-if="item.type === 'LINKED_DASHBOARDS'" :dashboards="item.elements" :loading="linkedDashboardsLoading" />
        </template>
      </v-virtual-scroll>
      <v-list-item v-if="devicesLoading" class="text-center">
        <v-progress-circular indeterminate color="primary" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script lang="ts" setup>
import { useDateFormat, useElementSize, useFps, useTimestamp } from '@vueuse/core'
import type { DashboardsEntry, LinkedDashboardsEntry } from '~/types/Dashboards'
import type { LoadableDeviceUnion } from '~/types/DeviceConfiguration'

// TODO remove
const fps = useFps()
const time = useTimestamp()
const computedTime = useDateFormat(time, 'YYYY-MM-DD HH:mm:ss SSS')

const model = defineModel<boolean>({ required: true })
const themeStore = useThemeStore()
const el = ref(null)
const search = ref<string | null>(null)
const settingsStore = useSettingsStore()
const devicesStore = useDevicesStore()
const dashboardsStore = useDashboardsStore()
const linkedDashboardsStore = useLinkedDashboardsStore()
const { linkedDashboards, isLoading: linkedDashboardsLoading } = storeToRefs(linkedDashboardsStore)
const { dashboardsAndGroups, isLoading: dashboardsLoading } = storeToRefs(dashboardsStore)
const { devices, isLoading: devicesLoading } = storeToRefs(devicesStore)
const { personalSettings, headbarHeight } = storeToRefs(settingsStore)
const filteredDevices = computed(() => {
  let elements = devices.value
  if (search.value && search.value.length > 0) {
    const hint = search.value.toLowerCase()
    elements = elements.filter(d => d.name.toLowerCase().indexOf(hint) >= 0 || d.hexacode.toLowerCase().indexOf(hint) >= 0)
  }
  return elements
})
const filteredDashboards = computed<DashboardsEntry>(() => {
  let elements = dashboardsAndGroups.value
  if (search.value && search.value.length > 0) {
    const hint = search.value.toLowerCase()
    elements = elements.filter((e) => {
      if (e.type === 'DashboardsGroupData') {
        return e.dashboards.findIndex(d => d.name.toLowerCase().indexOf(hint) >= 0) >= 0
      }
      return e.name.toLowerCase().indexOf(hint) >= 0
    })
  }
  return {
    id: 'dashboards',
    elements: elements,
    type: 'DASHBOARDS',
  }
})
const filteredLinkedDashboards = computed<LinkedDashboardsEntry>(() => {
  return {
    id: 'linked-dashboards',
    elements: linkedDashboards.value,
    type: 'LINKED_DASHBOARDS',
  }
})
type SidebarElements = LoadableDeviceUnion | DashboardsEntry | LinkedDashboardsEntry
const elements = computed<SidebarElements[]>(() => {
  return [filteredDashboards.value, filteredLinkedDashboards.value, ...filteredDevices.value]
})
const { height } = useElementSize(el)
</script>

<style lang="css" scoped>
.logo {
  height: 49px;
}
.v-theme--dark .logo {
  filter: brightness(0.0) invert(1) grayscale(1)
}
</style>
