<template>
  <div>
    <v-menu :close-on-content-click="false" location="bottom" eager width="350" max-height="640">
      <template #activator="{ props }">
        <v-badge color="error" :content="alarmsCounter" :model-value="hasAlarms" offset-x="4" offset-y="4" max="99">
          <v-btn variant="plain" :icon="mdiBellOutline" v-bind="props" color="secondary" density="comfortable" />
        </v-badge>
      </template>
      <v-list active-strategy="independent" open-strategy="single" active-class="text-secondary" lines="one">
        <v-list-item v-if="isLoading" class="text-center">
          <v-progress-circular indeterminate color="primary" />
        </v-list-item>
        <template v-if="!isLoading">
          <v-list-item v-if="hasAlarms">
            {{ $t("AlarmsMenu.DEPARTMENTS_WITH_ALARMS_MESSAGE", [alarmsCounter]) }}
          </v-list-item>
          <v-list-item v-if="hasAlarms">
            <v-text-field v-model="searchHint" :label="$t('AlarmsMenu.SEARCH_MESSAGE')" clearable hide-details />
          </v-list-item>
          <v-list-item v-else>
            {{ $t("AlarmsMenu.NO_ALARMS_MESSAGE") }}
          </v-list-item>
        </template>
        <div style="max-height: 350px;" class="overflow-y-auto w-100 px-3">
          <v-list ref="el" open-strategy="single" class="h-100 py-0" active-class="text-primary" lines="two">
            <v-virtual-scroll :items="alarms" :max-height="350">
              <template #default="{ item }">
                <headbar-alarm-device-entry v-if="item.type === 'REAL_DEVICE'" :entry="item" />
                <headbar-alarm-device-entry v-if="item.type === 'VIRTUAL_DEVICE'" :entry="item" />
                <headbar-alarm-business-group-entry v-if="item.type === 'BUSINESS_GROUP'" :entry="item" />
              </template>
            </v-virtual-scroll>
          </v-list>
        </div>
        <v-divider class="mt-2 mb-2" />
        <v-list-item>
          <v-btn block color="primary" @click="openAlarmHistoryDialog">
            <v-icon :icon="mdiClipboardTextClockOutline" size="x-large" class="mr-2" />
            {{ $t("AlarmsMenu.ALARM_HISTORY_MESSAGE") }}
          </v-btn>
        </v-list-item>
        <v-list-item v-if="isSupported">
          <v-btn block color="primary" @click="notificationSettingsAction">
            <v-icon :icon="notificationSettingsIcon" size="x-large" class="mr-2" />
            {{ notificationSettingsText }}
          </v-btn>
        </v-list-item>
        <v-list-item v-if="isSubscribed">
          <v-btn block color="primary" @click="sendTestNotification">
            <v-icon :icon="mdiBellRing" size="x-large" class="mr-2" />
            {{ $t("AlarmsMenu.TEST_ALARM") }}
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <lazy-alarm-history-dialog v-model="showAlarmHistoryDialog" />
  </div>
</template>

<script lang="ts" setup>
import { mdiBellCheckOutline, mdiBellOutline, mdiBellRemoveOutline, mdiBellRing, mdiClipboardTextClockOutline } from '@mdi/js'
import { useAsyncState } from '@vueuse/core'
import { useSubscription } from '@vueuse/rxjs'
import type { AxiosResponse } from 'axios'
import { filter, map } from 'rxjs'
import type { AlarmEntryUnion } from '~/types/Alarms'
import type { BusinessGroupAlarmEntryEvent, DeviceAlarmEntryEvent } from '~/types/WebSocket'

const { documentationUrl } = useAppConfig()
const { $notifications } = useNuxtApp()
const { t } = useI18n()
const securityStore = useSecurityStore()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
const { deviceAlarmEventStream, isLoggedIn } = storeToRefs(securityStore)
const { state, isLoading, execute } = useAsyncState(() => findDeviceAlarms().then(processAlarms), [], { shallow: true })
const { update } = useStateMutation(state)
const { isSupported, isSubscribed, enableNotifications, disableNotifications, eventStream } = usePushNotifications()
useSubscription(eventStream
  .pipe(filter(e => e.type === 'notification-click'))
  .pipe(map(e => e.payload))
  .pipe(filter(e => e.type === 'alarm'))
  .pipe(map(e => e.payload))
  .subscribe(alarmPage => navigateTo({ name: 'department-page-view', params: alarmPage }))
)
const notificationSettingsIcon = computed(() => isSubscribed.value ? mdiBellRemoveOutline : mdiBellCheckOutline)
const notificationSettingsText = computed(() => isSubscribed.value ? t('AlarmsMenu.notifications.disable') : t('AlarmsMenu.notifications.enable'))
const notificationSettingsAction = computed(() => isSubscribed.value ? disableNotifications : enablePushNotifications)
const alarmsCounter = computed(() => state.value.length)
const searchHint = ref<string>()
const showAlarmHistoryDialog = ref(false)
const hasAlarms = computed(() => alarmsCounter.value > 0)
const alarms = computed(() => {
  if (searchHint.value) {
    const hint = searchHint.value.toLowerCase()
    return state.value.filter(a =>
      a.name.toLowerCase().indexOf(hint) >= 0
      || a.hexacode.toLowerCase().indexOf(hint) >= 0
    )
  }
  return state.value
})
useSubscription(deviceAlarmEventStream.value.subscribe(handleAlarmEvent))
watch([() => personalSettings.value.language.code, isLoggedIn], () => {
  if (isLoggedIn.value) {
    loadAlarms()
  }
})
function handleAlarmEvent(event: DeviceAlarmEntryEvent | BusinessGroupAlarmEntryEvent) {
  update((alarms) => {
    const index = alarms.findIndex(a => a.hexacode === event.payload.hexacode)
    if (index >= 0) {
      alarms.splice(index, 1)
    }
    if (!event.payload.empty) {
      alarms.unshift(event.payload)
    }
  })
}
function processAlarms(response: AxiosResponse<AlarmEntryUnion[]>) {
  return response.data
}
function loadAlarms() {
  execute()
}
function openAlarmHistoryDialog() {
  showAlarmHistoryDialog.value = true
}
function enablePushNotifications() {
  enableNotifications().then((r) => {
    if (r === 'subscribed') {
      $notifications.success(t('AlarmsMenu.notifications.subscribed_successfully'))
    } else if (r === 'permission-denied') {
      $notifications.error(t('AlarmsMenu.notifications.permission_denied', [`${documentationUrl}/getting-started.html#push-notifications`]))
    } else {
      $notifications.error(t('AlarmsMenu.notifications.subscribe_error'))
    }
  })
}
function sendTestNotification() {
  sendTestPushNotification()
}
</script>

<style>

</style>
