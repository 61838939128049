<template>
  <v-list>
    <v-list-item-title>{{ name }}</v-list-item-title>
    <v-list-item-subtitle v-if="personalSettings.showHexacodesAsDeviceNames">{{ entry.hexacode }}</v-list-item-subtitle>
    <headbar-alarm-code
      v-for="(element, index) in entry.codes"
      :key="index"
      :entry="element"
      :hexacode="entry.hexacode"
      :department="entry.networkNumber || 0"
    />
    <headbar-alarm-department-entry
      v-for="(element, index) in entry.departments"
      :key="index"
      :entry="element"
    />
  </v-list>
</template>

<script lang="ts" setup>
import type { AbstractDeviceAlarmEntry } from '~/types/Alarms'

const { entry } = defineProps<{
  entry: AbstractDeviceAlarmEntry
}>()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
const name = computed(() => {
  if (entry.key && personalSettings.value.showDepartmentNumbers) {
    return `${entry.key} ${entry.name}`
  }
  return entry.name
})
</script>

<style>

</style>
