<template>
  <v-list-group>
    <template #activator="{ props }">
      <v-list-item v-bind="props" @click="loadDepartmentNames">
        <template #prepend>
          <v-icon :icon="icon" :color="color" />
        </template>
        <v-list-item-title>{{ key }} {{ name }}</v-list-item-title>
        <v-list-item-subtitle v-if="personalSettings.showHexacodesAsDeviceNames">{{ businessGroup.mappingHexacode }}</v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-progress-linear v-if="businessGroup.loading" indeterminate color="primary" />
    <sidebar-department-element v-for="department in departments" :key="department.id" :department="department" link />
    <sidebar-device-element v-for="device in businessGroup.devices" :key="device.id" :device="device" link sub-group />
  </v-list-group>
</template>

<script lang="ts" setup>
import type { LoadableBusinessGroup } from '~/types/DeviceConfiguration'

const { businessGroup } = defineProps<{
  businessGroup: LoadableBusinessGroup
}>()
const devicesStore = useDevicesStore()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
const key = computed(() => personalSettings.value.showDepartmentNumbers ? businessGroup.key : '')
const name = computed(() => businessGroup.configuration.label ? businessGroup.configuration.label : businessGroup.name)
const departments = computed(() => businessGroup.departments.filter(d => d.networkController ? personalSettings.value.showNetworkControllers : true))

const color = computed(() => Status[businessGroup.status].color)
const icon = computed(() => Status[businessGroup.status].icon)

const loadDepartmentNames = () => {
  devicesStore.loadDepartmentNames(businessGroup)
}
</script>

<style>

</style>
