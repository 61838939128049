<template>
  <div>
    <v-list-item link :to="route" :disabled="!status.isOnline">
      <div class="d-flex align-center">
        <v-icon :color="status.color" :icon="status.icon" />
        <div class="pl-1">
          <div>{{ name }}</div>
          <div v-if="entry.codes.length == 0">{{ timestamp }}</div>
        </div>
      </div>
      <headbar-alarm-code
        v-for="(code, index) in entry.codes"
        :key="index"
        :hexacode="entry.hexacode"
        :department="entry.department"
        :sub-department="entry.subDepartment"
        :entry="code"
      />
    </v-list-item>
    <v-divider />
  </div>
</template>

<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { DepartmentAlarmEntry } from '~/types/Alarms'

const { entry } = defineProps<{
  entry: DepartmentAlarmEntry
}>()
const deviceStore = useDevicesStore()
const settingsStore = useSettingsStore()
const timestamp = computed(() => DateTime.fromISO(entry.timestamp, { zone: 'utc' }).toFormat(settingsStore.personalSettings.dateFormat))
const name = computed(() => {
  if (entry.key && settingsStore.personalSettings.showDepartmentNumbers) {
    const key = entry.key.split('.')[1]
    return `${key} ${entry.name}`
  }
  return entry.name
})
const status = computed(() => {
  let device = deviceStore.devices.find(d => d.hexacode === entry.hexacode)
  if (device) {
    if (device.type === 'BUSINESS_GROUP') {
      const mapping = device.devices.find(d => d.networkNumber === entry.department)
      if (!mapping) {
        return Status[device.status]
      }
      device = mapping
    }
    const department = device.departments.find(d => d.department === entry.department && d.subDepartment === entry.subDepartment)
    if (department) {
      return Status[department.status]
    }
    return Status[device.status]
  }
  return Status.OFF
})
const route = computed(() => {
  return {
    name: 'department-page-view',
    params: {
      hexacode: entry.hexacode,
      department: entry.department,
      subDepartment: entry.subDepartment,
      page: entry.page,
    },
  }
})
</script>

<style>

</style>
