<template>
  <div>
    <v-list-item-title>{{ entry.name }}</v-list-item-title>
    <v-list-item-subtitle v-if="personalSettings.showHexacodesAsDeviceNames">{{ entry.hexacode }}</v-list-item-subtitle>
    <headbar-alarm-device-entry
      v-for="(deviceEntry, index) in entry.devices"
      :key="index"
      :entry="deviceEntry"
      class="pl-4"
    />
  </div>
</template>

<script lang="ts" setup>
import type { BusinessGroupAlarmEntry } from '~/types/Alarms'

const { entry } = defineProps<{
  entry: BusinessGroupAlarmEntry
}>()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
</script>

<style>

</style>
