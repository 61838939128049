<template>
  <v-list-group>
    <template #activator="{ props }">
      <v-list-item v-bind="props" @click="loadDepartmentNames">
        <template #prepend>
          <v-icon :icon="icon" :color="color" />
        </template>
        <v-list-item-title>{{ key }} {{ name }}</v-list-item-title>
        <v-list-item-subtitle v-if="personalSettings.showHexacodesAsDeviceNames">{{ device.mappingHexacode }}</v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-progress-linear v-if="device.loading" indeterminate color="primary" />
    <sidebar-department-element v-for="department in departments" :key="department.id" :department="department" link />
  </v-list-group>
</template>

<script lang="ts" setup>
import type { LoadableRealDevice, LoadableVirtualDevice } from '~/types/DeviceConfiguration'

const { device } = defineProps<{
  device: LoadableRealDevice | LoadableVirtualDevice
}>()
const devicesStore = useDevicesStore()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
const key = computed(() => personalSettings.value.showDepartmentNumbers ? device.key : '')
const name = computed(() => device.configuration.label ? device.configuration.label : device.name)
const departments = computed(() => device.departments.filter(d => d.networkController ? personalSettings.value.showNetworkControllers : true))
const color = computed(() => Status[device.status].color)
const icon = computed(() => Status[device.status].icon)

const loadDepartmentNames = () => {
  devicesStore.loadDepartmentNames(device)
}
</script>

<style>

</style>
