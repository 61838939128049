<template>
  <v-menu :close-on-content-click="false" location="bottom" eager width="290">
    <template #activator="{ props }">
      <v-btn variant="plain" tile v-bind="props" color="secondary">
        <v-icon :icon="mdiAccountCircleOutline" size="x-large" />
        <span class="d-none d-sm-block">{{ securityStore.username }}</span>
      </v-btn>
    </template>
    <v-list active-strategy="independent" open-strategy="single" active-class="text-primary" class="no-user-select" lines="one">
      <template v-for="(entry, index) in menu" :key="index">
        <v-divider v-if="entry.divider" />
        <v-list-group v-else-if="entry.children && entry.render">
          <template #activator="{ props }">
            <v-list-item v-bind="props" :prepend-icon="entry.icon" :title="entry.title" />
          </template>
          <template v-for="(subEntry, i2) in entry.children" :key="i2">
            <v-list-item v-if="subEntry.render" :prepend-icon="subEntry.icon" :title="subEntry.title" v-bind="subEntry.props" v-on="subEntry.on" />
          </template>
        </v-list-group>
        <v-list-item v-else-if="entry.render" :prepend-icon="entry.icon" :title="entry.title" v-on="entry.on" />
      </template>
    </v-list>
    <lazy-dialog-personal-settings v-model="accountSettingsDialog" />
    <lazy-dialog-switch-account v-model="switchAccountDialog" />
    <lazy-dialog-bug-report v-model="bugReportDialog" />
    <lazy-dialog-request-feature v-model="featureRequestDialog" />
    <lazy-dialog-devices-configuration v-model="deviceConfigurationDialog" />
    <lazy-dialog-dashboards-configuration v-model="dashboardSortingDialog" />
    <lazy-dashboard-import-dialog v-model="dashboardImportDialog" />
    <lazy-dialog-theme-settings v-model="themeSettingsDialog" />
    <lazy-dashboard-shared-links-management-dialog v-model="dashboardSharedLinksDialog" />
  </v-menu>
</template>

<script lang="ts" setup>
import {
  mdiAccountCircleOutline,
  mdiAccountCogOutline,
  mdiAccountSwitchOutline,
  mdiAccountSyncOutline,
  mdiCardPlusOutline,
  mdiDownload,
  mdiFileCogOutline,
  mdiFileDocumentOutline,
  mdiHelpCircleOutline,
  mdiLogout,
  mdiMonitorArrowDown,
  mdiNewBox,
  mdiPaletteOutline,
  mdiSecurity,
  mdiShareVariantOutline,
  mdiShieldBugOutline,
  mdiSort,
  mdiUpload,
  mdiViewDashboardOutline
} from '@mdi/js'
import * as zod from 'zod'
import { DashboardSchema } from '~/types/Dashboards'

interface MenuItem {
  title?: string
  icon?: string
  divider?: boolean
  render: Ref<boolean> | boolean
  children?: MenuItem[]
  on?: Record<string, () => void>
  props?: Record<string, unknown>
}

const { documentationUrl } = useAppConfig()
const { t } = useI18n()
const { $notifications, $prompts } = useNuxtApp()
const { isInstalled, install } = useStandaloneInstaller()
const dashboardsStore = useDashboardsStore()
const securityStore = useSecurityStore()
const { isLoggedInAs } = storeToRefs(securityStore)
const accountSettingsDialog = ref(false)
const themeSettingsDialog = ref(false)
const deviceConfigurationDialog = ref(false)
const switchAccountDialog = ref(false)
const bugReportDialog = ref(false)
const featureRequestDialog = ref(false)
const dashboardSortingDialog = ref(false)
const dashboardImportDialog = ref(false)
const dashboardSharedLinksDialog = ref(false)
const isAdmin = computed(() => securityStore.permissions.SYSTEM.WRITE())
const menu = computed<MenuItem[]>(() => {
  return [
    {
      title: t('AccountMenu.DASHBOARD_MENU_TITLE'),
      icon: mdiViewDashboardOutline,
      render: ref(true),
      children: [
        { title: t('AccountMenu.CREATE_DASHBOARD'), render: true, icon: mdiCardPlusOutline, props: {}, on: { click: createDashboard } },
        { title: t('AccountMenu.EXPORT_DASHBOARD'), render: true, icon: mdiDownload, props: {}, on: { click: exportDashboard } },
        { title: t('AccountMenu.IMPORT_DASHBOARD'), render: true, icon: mdiUpload, props: {}, on: { click: () => dashboardImportDialog.value = true } },
        { title: t('AccountMenu.SORT_DASHBOARD'), render: true, icon: mdiSort, props: {}, on: { click: () => dashboardSortingDialog.value = true } },
        { title: t('AccountMenu.SHARED_LINKS'), render: true, icon: mdiShareVariantOutline, props: {}, on: { click: () => dashboardSharedLinksDialog.value = true } },
      ],
    },
    { title: t('AccountMenu.PERSONAL_SETTINGS'), render: true, icon: mdiAccountCogOutline, on: { click: () => accountSettingsDialog.value = true } },
    { title: t('AccountMenu.DEVICE_CONFIGURATION'), render: true, icon: mdiFileCogOutline, on: { click: () => deviceConfigurationDialog.value = true } },
    { title: t('AccountMenu.THEME_CONFIGURATION'), render: true, icon: mdiPaletteOutline, on: { click: () => themeSettingsDialog.value = true } },
    {
      title: t('AccountMenu.HELP_MENU_TITLE'),
      icon: mdiHelpCircleOutline,
      render: true,
      children: [
        { title: t('AccountMenu.OPEN_DOCUMENTATION'), render: true, icon: mdiFileDocumentOutline, props: { href: documentationUrl, target: '_blank' }, on: { click: () => {} } },
        { title: t('AccountMenu.REQUEST_FEATURE'), render: true, icon: mdiNewBox, props: {}, on: { click: () => featureRequestDialog.value = true } },
        { title: t('AccountMenu.REPORT_BUG'), render: true, icon: mdiShieldBugOutline, props: {}, on: { click: () => bugReportDialog.value = true } },
      ],
    },
    { divider: true, render: true },
    {
      title: t('AccountMenu.ADMIN_MENU_TITLE'),
      icon: mdiSecurity,
      render: isAdmin.value || isLoggedInAs.value,
      children: [
        { title: t('AccountMenu.SWITCH_BACK_ACCOUNT'), render: isLoggedInAs.value, icon: mdiAccountSyncOutline, props: {}, on: { click: switchAccount } },
        { title: t('AccountMenu.SWITCH_USER'), render: isAdmin.value, icon: mdiAccountSwitchOutline, props: {}, on: { click: () => switchAccountDialog.value = true } },
      ],
    },
    { title: t('AccountMenu.INSTALL_APP'), render: !isInstalled.value, icon: mdiMonitorArrowDown, on: { click: install } },
    { divider: true, render: ref(true) },
    { title: t('AccountMenu.LOGOUT'), render: true, icon: mdiLogout, on: { click: logout } },
  ]
})
function logout() {
  securityStore.unload()
  navigateTo('/login')
}
function switchAccount() {
  switchAccountBack().then((r) => {
    securityStore.unload()
    nextTick(() => {
      securityStore.load(r.data)
      navigateTo('/')
    })
    $notifications.success(t('SwitchAccountForm.BACK_SUCCESS_MESSAGE'))
  }).catch(() => $notifications.error(t('SwitchAccountForm.ERROR_MESSAGE')))
}
function createDashboard() {
  $prompts.input(t('AccountMenu.DASHBOARD_SAVE_DIALOG_TITLE'), 'Title', false, zod.string().trim().min(1))
    .then(r => dashboardsStore.createDashboard(r))
    .then((d) => {
      $notifications.success(t('AccountMenu.DASHBOARD_SAVE_SUCCESS'))
      navigateTo(`/dashboards/${d.id}`)
    })
    .catch(e => $notifications.error(t('AccountMenu.DASHBOARD_SAVE_ERROR', [e])))
}
function exportDashboard() {
  $prompts.select(t('AccountMenu.DASHBOARD_EXPORT_DIALOG_TITLE'), 'Dashboard', dashboardsStore.dashboards, 'name', DashboardSchema)
    .then((r) => {
      const dashboard = JSON.stringify(r)
      const blob = new Blob([dashboard], { type: 'application/json' })
      const elem = window.document.createElement('a')
      elem.href = window.URL.createObjectURL(blob)
      elem.download = r.name
      document.body.appendChild(elem)
      elem.click()
      document.body.removeChild(elem)
      URL.revokeObjectURL(elem.href)
    })
    .catch((_e) => {
      $notifications.error(t('AccountMenu.DASHBOARD_DOWNLOAD_ERROR'))
    })
}
</script>

<style lang="css" scoped>

</style>
