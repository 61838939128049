<template>
  <v-list-item :active="isActive" @click.stop="openDashboard">
    <v-list-item-title class="pl-4">{{ dashboard.name }}</v-list-item-title>
    <template #append>
      <v-btn color="primary" :icon="icon" variant="text" density="compact" @click.stop="setFavorite" />
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { mdiStar, mdiStarOutline } from '@mdi/js'
import type { Dashboard } from '~/types/Dashboards'

const { dashboard } = defineProps<{
  dashboard: Dashboard
}>()
const route = useRoute()
const { t } = useI18n()
const { $notifications } = useNuxtApp()
const dashboardsStore = useDashboardsStore()
const isActive = computed(() => route.params.id === dashboard.id)
const icon = computed(() => {
  if (dashboard.favorite) {
    return mdiStar
  }
  return mdiStarOutline
})
const targetRoute = computed(() => {
  return {
    name: 'dashboard',
    params: {
      id: dashboard.id,
    },
  }
})
function openDashboard() {
  navigateTo(targetRoute.value)
}
function setFavorite() {
  if (!dashboard.favorite) {
    dashboardsStore.updateFavorite(dashboard.id!).then(() => {
      $notifications.success(t('SidebarDashboardNode.DASHBOARD_SET_FAVORITE_MESSAGE', [dashboard.name]))
    }).catch(e => $notifications.error(t('SidebarDashboardNode.DASHBOARD_FAVORITE_ERROR', [e])))
  }
}
</script>

<style>

</style>
