export const useStandaloneInstaller = () => {
  const { t } = useI18n()
  const { $pwa, $prompts } = useNuxtApp()
  const isInstalled = computed(() => !$pwa!.showInstallPrompt)
  function install() {
    $prompts.confirm(t('standalone.install_prompt.title'), t('standalone.install_prompt.body'), false).then(() => {
      $pwa?.install()
    })
  }
  return {
    isInstalled,
    install,
  }
}
