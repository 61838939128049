<template>
  <v-list-item :to="route" :disabled="!status.isOnline">
    <div class="d-flex align-center">
      <v-icon :color="status.color" :icon="status.icon" />
      <div class="pl-1">
        <div class="font-weight-bold">{{ label }}</div>
        <div>
          {{ timestamp }} <span v-if="entry.offline">(UTC)</span>
        </div>
      </div>
    </div>
  </v-list-item>
</template>

<script lang="ts" setup>
import { DateTime } from 'luxon'
import type { AlarmCode } from '~/types/Alarms'

const { hexacode, department, subDepartment, entry } = defineProps<{
  hexacode: string
  department: number
  subDepartment?: number
  entry: AlarmCode
}>()
const devicesStore = useDevicesStore()
const settingsStore = useSettingsStore()
const timestamp = computed(() => DateTime.fromISO(entry.timestamp, { zone: 'utc' }).toFormat(settingsStore.personalSettings.dateFormat))
const label = computed(() => {
  if (entry.label) {
    return entry.label
  }
  return `Code ${entry.code}`
})
const route = computed(() => {
  if (entry.address) {
    return {
      name: 'department-page-view',
      params: {
        hexacode: hexacode,
        department: entry.address.department,
        subDepartment: entry.address.subDepartment,
        page: entry.address.page,
      },
    }
  }
  return undefined
})
const status = computed(() => {
  let device = devicesStore.devices.find(d => d.hexacode === hexacode)
  if (device) {
    if (device.type === 'BUSINESS_GROUP') {
      const mapping = device.devices.find(d => d.networkNumber === department)
      if (!mapping) {
        return Status[device.status]
      }
      device = mapping
    }
    const departmentEntry = device.departments.find(d => d.department === department && d.subDepartment === subDepartment)
    if (departmentEntry) {
      return Status[departmentEntry.status]
    }
    return Status[device.status]
  }
  return Status.ON_ALARM
})
</script>

<style>

</style>
