import { createGlobalState } from '@vueuse/core'
import { DateTime } from 'luxon'
import { Subject } from 'rxjs'
import type { ServiceWorkerMessage } from '~/service-worker/types'
import type { VapidSubscription } from '~/types/Security'

type VapidSubscriptionStatus = 'not-supported' | 'not-logged-in' | 'no-vapid-key' | 'permission-denied' | 'subscribed' | 'error'
const supportsPushNotifications = typeof window !== 'undefined'
  && 'serviceWorker' in navigator
  && 'PushManager' in window
  && 'Notification' in window
  && 'getKey' in PushSubscription.prototype

const useServiceWorkerMessageBus = createGlobalState(() => {
  const eventStream = new Subject<ServiceWorkerMessage>()
  navigator.serviceWorker.addEventListener('message', (event: MessageEvent<ServiceWorkerMessage>) => {
    eventStream.next(event.data)
  })
  return {
    eventStream,
  }
})

export const usePushNotifications = () => {
  const securityStore = useSecurityStore()
  const { isLoggedIn, vapidSubscription, vapidKey } = storeToRefs(securityStore)
  const { eventStream } = useServiceWorkerMessageBus()
  const isSubscribed = ref(false)
  const isSupported = computed(() => supportsPushNotifications)
  const isEnabled = computed(() => notificationPermission.value === 'granted')
  const notificationPermission = ref<PermissionState | undefined>(
    (supportsPushNotifications
      && Notification.permission === 'denied')
      ? 'denied'
      : Notification.permission === 'granted'
        ? 'granted'
        : Notification.permission === 'default'
          ? 'prompt'
          : undefined
  )
  watch(vapidSubscription, () => {
    isSubscribed.value = vapidSubscription.value !== null && isEnabled.value
  }, { immediate: true })

  function subscribe(registration: ServiceWorkerRegistration) {
    return registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidKey.value!),
    })
      .then(convertSubscription)
      .then(subscription => subscribeToPushNotifications(subscription))
      .then(r => vapidSubscription.value = r.data)
  }
  function unsubscribe() {
    return getRegistration()
      .then(getPushSubscription)
      .then(({ subscription }) => {
        if (subscription) {
          return subscription.unsubscribe()
        }
        return Promise.resolve(true)
      })
  }
  function trySubscribe() {
    return getRegistration()
      .then(getPushSubscription)
      .then(({ registration, subscription }) => {
        if (subscription) {
          const currentServerKey = (new Uint8Array(subscription.options.applicationServerKey!)).toString()
          const subscriptionServerKey = urlBase64ToUint8Array(vapidKey.value!).toString()
          if (currentServerKey === subscriptionServerKey && vapidSubscription.value !== null && subscription.endpoint === vapidSubscription.value?.endpoint) {
            return Promise.resolve(vapidSubscription.value!)
          } else if (vapidSubscription.value !== null) {
            // remove old subscription
            return unsubscribeFromPushNotifications()
              .then(() => subscribe(registration))
          }
        }
        return subscribe(registration)
      }).catch((e) => {
        return getRegistration()
          .then(getPushSubscription)
          .then(unsubscribeFromPushNotifications)
          .then(unsubscribe)
          .then(() => { vapidSubscription.value = null })
          .then(() => Promise.resolve(null))
          .catch(_e => Promise.resolve(null))
          .finally(() => Promise.reject(e))
      })
  }
  function enableNotifications(): Promise<VapidSubscriptionStatus> {
    if (!isSupported.value) {
      return Promise.resolve('not-supported')
    }
    if (!isLoggedIn.value) {
      return Promise.resolve('not-logged-in')
    }
    if (!vapidKey.value) {
      return Promise.resolve('no-vapid-key')
    }
    return Promise.resolve(Notification.requestPermission())
      .then(p => p === 'default' ? 'prompt' : p)
      .then((p) => {
        if (p === 'denied') {
          return 'permission-denied'
        }
        return trySubscribe().then(r => r !== null ? 'subscribed' : 'error')
      })
  }
  function disableNotifications() {
    if (isSupported.value && isEnabled.value && isSubscribed.value) {
      return unsubscribeFromPushNotifications()
        .then(() => { vapidSubscription.value = null })
        .then(unsubscribe)
    }
    return Promise.resolve(true)
  }
  return {
    isEnabled,
    isSubscribed,
    isSupported,
    eventStream,
    enableNotifications,
    disableNotifications,
  }
}
function convertSubscription(subscription: PushSubscription): VapidSubscription {
  return {
    endpoint: subscription!.endpoint,
    keys: {
      p256dh: btoa(String.fromCharCode(...new Uint8Array(subscription!.getKey('p256dh')!))),
      auth: btoa(String.fromCharCode(...new Uint8Array(subscription!.getKey('auth')!))),
    },
    expirationDate: subscription!.expirationTime !== null
      ? DateTime.fromMillis(subscription!.expirationTime).toISO({ includeOffset: false })
      : null,
  }
}
function getRegistration() {
  return navigator.serviceWorker.ready
}
async function getPushSubscription(registration: ServiceWorkerRegistration) {
  const subscription = await registration.pushManager.getSubscription()
  return { registration, subscription }
}
/**
 * Taken from https://www.npmjs.com/package/web-push
 */
function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = `${base64String}${padding}`
    .replace(/-/g, '+')
    .replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i)
    outputArray[i] = rawData.charCodeAt(i)

  return outputArray
}
