<template>
  <v-app-bar :height="headbarHeight">
    <v-app-bar-nav-icon color="secondary" density="comfortable" @click="model = !model" />
    <v-breadcrumbs :items="breadcrumpItems" class="font-weight-bold" />
    <v-spacer />
    <headbar-alarms-menu />
    <headbar-account-menu />
  </v-app-bar>
</template>

<script lang="ts" setup>
import type { DeviceConfiguration } from '~/types/DeviceConfiguration'

const model = defineModel<boolean>({ required: true })
const { currentRoute } = useRouter()

const settingsStore = useSettingsStore()
const { headbarHeight } = storeToRefs(settingsStore)

const { mdAndUp } = useDisplay()
const devicesStore = useDevicesStore()
const { devices } = storeToRefs(devicesStore)
const dashboardsStore = useDashboardsStore()
const { dashboards } = storeToRefs(dashboardsStore)
const breadcrumpItems = computed(() => {
  const items: string[] = []
  if (currentRoute.value.name === 'dashboard' && currentRoute.value.params.id) {
    const selectedDashboard = dashboards.value.find(d => d.id === currentRoute.value.params.id)
    if (selectedDashboard) {
      items.push(selectedDashboard.name)
    }
  } else if (currentRoute.value.name === 'department-page-view') {
    const device = devices.value.find(d => d.hexacode === currentRoute.value.params.hexacode)
    if (device) {
      if (mdAndUp.value) {
        items.push(getName(device.name, device.configuration))
      }
      const departmentNumber = parseInt(currentRoute.value.params.department as string)
      const subDepartmentNumber = parseInt(currentRoute.value.params.subDepartment as string)
      if (device.type === 'BUSINESS_GROUP') {
        if (departmentNumber === 0) {
          const department = device.departments.find(d => d.department === departmentNumber && d.subDepartment === subDepartmentNumber)
          if (department) {
            items.push(getName(department.name, department.configuration))
          }
        } else {
          const mapping = device.devices.find(d => d.networkNumber === departmentNumber)
          if (mapping) {
            if (mdAndUp.value) {
              items.push(getName(mapping.name, mapping.configuration))
            }
            const department = mapping.departments.find(d => d.subDepartment === subDepartmentNumber)
            if (department) {
              items.push(getName(department.name, department.configuration))
            }
          }
        }
      } else {
        const department = device.departments.find(d => d.department === departmentNumber && d.subDepartment === subDepartmentNumber)
        if (department) {
          items.push(getName(department.name, department.configuration))
        }
      }
    }
  }
  return items
})
function getName(defaultName: string, configuration: DeviceConfiguration) {
  return configuration.label !== null ? configuration.label : defaultName
}
</script>

<style>

</style>
