<template>
  <v-list-item :active="isActive" @click.stop="openDashboard">
    <v-list-item-title class="pl-4">{{ dashboard.name }}</v-list-item-title>
    <template #append>
      <v-btn color="primary" :icon="mdiLinkVariant" variant="text" density="compact" @click.stop="copyLink" />
      <v-btn color="primary" :icon="mdiLinkVariantRemove" variant="text" density="compact" @click.stop="removeLink" />
    </template>
  </v-list-item>
</template>

<script lang="ts" setup>
import { mdiLinkVariant, mdiLinkVariantRemove } from '@mdi/js'
import type { LinkedDashboard } from '~/types/Dashboards'

const { dashboard } = defineProps<{
  dashboard: LinkedDashboard
}>()
const route = useRoute()
const { t } = useI18n()
const { $notifications } = useNuxtApp()
const { copyToClipboard } = useCopyToClipboard()
const linkedDashboardsStore = useLinkedDashboardsStore()
const isActive = computed(() => route.params.id === dashboard.token.dashboardShareToken)
const targetRoute = computed(() => {
  return {
    name: 'shared-dashboard',
    params: {
      id: dashboard.token.dashboardShareToken,
    },
  }
})
function openDashboard() {
  navigateTo(targetRoute.value)
}
function copyLink() {
  copyToClipboard(`${origin}/shared/dashboard/${dashboard.token.dashboardShareToken}`)
}
function removeLink() {
  linkedDashboardsStore.removeDashboardLink(dashboard.token.id).then(() => {
    $notifications.success(t('Sidebar.LinkedDashboards.Unlink.Success', [dashboard.name]))
  }).catch(e => $notifications.error(t('Sidebar.LinkedDashboards.Unlink.Error', [e])))
}
</script>

<style>

</style>
