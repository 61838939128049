<template>
  <v-list-group sub>
    <template #activator="{ props }">
      <v-list-item v-bind="props" @click="loadDepartmentPages">
        <template #prepend>
          <v-icon :icon="icon" :color="color" />
        </template>
        <v-list-item-title>{{ key }} {{ name }}</v-list-item-title>
        <v-list-item-subtitle v-if="personalSettings.showHexacodesAsDeviceNames">{{ department.mappingHexacode }}</v-list-item-subtitle>
      </v-list-item>
    </template>
    <v-progress-linear v-if="department.loading" indeterminate color="primary" />
    <v-list-item v-for="page in department.pages" :key="page.number" link :to="createRoute(page)">
      <v-list-item-title>{{ page.number }} {{ page.name }}</v-list-item-title>
    </v-list-item>
  </v-list-group>
</template>

<script lang="ts" setup>
import type { DepartmentPage } from '~/types/Device'
import type { LoadableDepartment } from '~/types/DeviceConfiguration'

const { department } = defineProps<{
  department: LoadableDepartment
}>()
const { t } = useI18n()
const { $notifications } = useNuxtApp()
const devicesStore = useDevicesStore()
const settingsStore = useSettingsStore()
const { personalSettings } = storeToRefs(settingsStore)
const key = computed(() => personalSettings.value.showDepartmentNumbers ? department.key : '')
const name = computed(() => department.configuration.label ? department.configuration.label : department.name)
const color = computed(() => Status[department.status].color)
const icon = computed(() => Status[department.status].icon)
function createRoute(page: DepartmentPage) {
  return {
    name: 'department-page-view',
    params: {
      hexacode: department.hexacode,
      department: department.department,
      subDepartment: department.subDepartment,
      page: page.number,
    },
  }
}

function loadDepartmentPages() {
  if (!Status[department.status].isOnline) {
    $notifications.warning(t('SidebarDepartmentNode.OFFLINE_DEPARTMENT_MESSAGE', [name.value]))
    return
  }
  devicesStore.loadDepartmentPages(department).catch(() => {
    $notifications.error(t('DevicePageView.CANNOT_LOAD_PAGES_MESSAGE', [name.value]))
  })
}
</script>

<style>

</style>
