<template>
  <v-app>
    <sidebar v-model="visible" />
    <headbar v-model="visible" />
    <v-main>
      <v-banner v-show="$pwa?.needRefresh" stacked sticky color="primary" lines="one" :height="headbarHeight" class="banner py-0 align-content-center">
        <template #prepend>
          <v-icon :icon="mdiCellphoneArrowDown" class="mt-2" />
        </template>
        <template #text>
          New version available.
        </template>
        <template #actions>
          <v-btn text="Update" variant="text" @click="updateServiceWorker" />
        </template>
      </v-banner>
      <v-container fluid class="h-100">
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script lang="ts" setup>
import { mdiCellphoneArrowDown } from '@mdi/js'
import { useDisplay } from 'vuetify'

const visible = ref(true)
const { mobile } = useDisplay()
const settingsStore = useSettingsStore()
const { headbarHeight } = storeToRefs(settingsStore)
const { $pwa } = useNuxtApp()
onMounted(() => {
  visible.value = !mobile.value
})
function updateServiceWorker() {
  $pwa?.updateServiceWorker(true)
    .catch(e => console.log(e))
}
</script>

<style scoped>
.banner {
  z-index: 1007;
}
</style>
